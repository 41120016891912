/*
 * @Author: Abhi Bhatt
 * @Date:   2020-07-09 16:27:44
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-05-06 12:39:55
 */
import React from 'react';
import { Link } from 'react-router-dom';
import LocationService from './../../services/LocationService/LocationService';
import GearSpinner from '../../components/GearSpinner/GearSpinner';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class LocationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_data: {},
      inventory_place_id: '',
      location_detail_data: [],
      loading: false,
    };
    this.locationService = new LocationService();
  }

  componentDidMount() {
    const permalink = this.props.match.params.permalink;
    this.setState({ loading: true });
    this.locationService.getLocationDetail(permalink).then((request) => {
      if (request.status === 200) {
        this.setState({
          location_data:
            request.data.result.inventory_place[0].inventory_place[0].fields,
        });
        this.setState({
          inventory_place_id:
            request.data.result.inventory_place[0].inventory_place[0].pk,
        });
        this.setState({
          location_detail_data:
            request.data.result.inventory_place[0].inventories,
        });
      }
      this.setState({ loading: false });
    });
  }

  renderLocationDetail() {
    let location_detail_data = this.state.location_detail_data;
    if (location_detail_data && location_detail_data.length > 0) {
      return Object(location_detail_data).map(function (value, key) {
        return (
          <React.Fragment key={key}>
            <tr>
              <td>
                <Link
                  to={'/motors/' + value.fields.motor}
                  className="link-with-icon"
                >
                  {value.fields.motor}
                </Link>
              </td>
              <td>{value.fields.count}</td>
            </tr>
          </React.Fragment>
        );
      });
    }
  }

  render() {
    let location_data = this.state.location_data;
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={'/'}>Home</a>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={'/locations'}>Locations</Link>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item active">
                      {location_data.name}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row px-1">
                <div className="product-content px-5">
                  <div className="products">
                    <div className="row">
                      <div className="col-md-8">
                        <h3>
                          <strong>{location_data.name}</strong>
                        </h3>
                        <h4>
                          {this.state.location_detail_data.length} items
                          available
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <article className="motors">
                          {this.state.loading ? (
                            <GearSpinner />
                          ) : (
                            <table className="table inventory">
                              <thead>
                                <tr>
                                  <th>Model Number</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderLocationDetail()}</tbody>
                            </table>
                          )}
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default LocationDetails;
