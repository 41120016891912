/*
* @Author: Abhi Bhatt
* @Date:   2020-06-09 18:19:25
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-07 13:27:31
*/
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import HomeHeroSlider from '../../components/Slider/HomeHeroSlider';
import HomeProductSlider from '../../components/Slider/HomeProductSlider';
import HomeIndustrySlider from '../../components/Slider/HomeIndustrySlider';
import icon1 from '../../images/premium-efficiency.png';
import icon2 from '../../images/ingress-protection.png';
import icon3 from '../../images/vfd-inverter-duty.png';
import icon4 from '../../images/extra-strength.png';
import icon5 from '../../images/worry-free-maintenance.png';
import qualityIcon from '../../images/quality.png';
import performanceIcon from '../../images/performance.png';
import availabilityIcon from '../../images/availability.png';
import customizationIcon from '../../images/customization.png';

import banner2 from '../../images/banner/banner2.jpg';

const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: []
      //redirect: false
    };
    this.renderChooseSection = this.renderChooseSection.bind(this);
    this.renderIndustriesSection = this.renderIndustriesSection.bind(this);
  }

  componentDidMount() {
    
  }

  renderIndustriesSection() {
    return (
      <React.Fragment>
        <section className="techtop-industries-section dark-green-bg-color py-2"> 
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-white">Industries</h2>
                </div>
              </div>
              <HomeIndustrySlider/>  
              <hr/>
            </div>
        </section>
      </React.Fragment>
    )
  }

  renderChooseSection() {
    return (
      <React.Fragment>
        <section className="techtop-choose-us-section dark-green-bg-color pt-2 pb-4"> 
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h2 className="text-white text-right font-42">Why <span className="txt-color1">Should You</span> Choose Us?</h2>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3 col-6">
                      <div className="techtop-infoBox3">
                        <img src={qualityIcon} className="img-fluid d-block w-100" alt="img9"/>
                        <h6>Quality</h6>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="techtop-infoBox3">
                        <img src={performanceIcon} className="img-fluid d-block w-100" alt="img12"/>
                        <h6>Performance</h6>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="techtop-infoBox3">
                        <img src={availabilityIcon} className="img-fluid d-block w-100" alt="img2"/>
                        <h6>Availability</h6>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="techtop-infoBox3">
                        <img src={customizationIcon} className="img-fluid d-block w-100" alt="img11"/>
                        <h6>Customization</h6>
                      </div>
                    </div>
                  </div>   
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="techtop-bg" style={{ backgroundImage: `url(${banner2})` }}>
                    <p className="text-center text-white font-24 m-0">Techtop Motors is one of the fastest-growing electric motor manufacturers in the industry today. Each Techtop motor is quality crafted with protection features that keep it running. Twelve stocking warehouses within the US and 13 worldwide ensure that your motor will be ready when you need it. Our pricing, freight policy, availability, and overall service separate us from the competition. Call your local representative to find out the unbeatable Techtop price!</p>
                  </div>  
                </div>
              </div>  
            </div>
        </section> 
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <main className="techtop-middle-div">
          <HomeHeroSlider/>
          <section className="techtop-img-logo-section">  
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="techtop-infoBox">
                    <img src={icon1} className="img-fluid" alt="icon1"/>
                    <h6>PREMIUM EFFICIENCY</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="techtop-infoBox">
                    <img src={icon2} className="img-fluid" alt="icon2"/>
                    <h6>INGRESS PROTECTION</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="techtop-infoBox">
                    <img src={icon3} className="img-fluid" alt="icon3"/>
                    <h6>VFD INVERTER DUTY</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="techtop-infoBox">
                    <img src={icon4} className="img-fluid" alt="icon4"/>
                    <h6>EXTRA STRENGTH</h6>
                  </div>
                </div>
                <div className="col">
                  <div className="techtop-infoBox">
                    <img src={icon5} className="img-fluid" alt="icon5"/>
                    <h6>WORRY-FREE MAINTENANCE</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="techtop-product-section dark-green-bg-color pt-5 pb-1"> 
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <h2 className="text-white">Products</h2>
                </div>
                <div className="col-4">
                  <p className="view-all-text"><Link to={'/products'}>View All</Link></p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <HomeProductSlider/>
                </div>    
              </div>
              <hr/>  
            </div>
          </section> 
          {this.renderIndustriesSection()}
          {this.renderChooseSection()}  
        </main>
      </React.Fragment>
    );
  }
}

export default Home;
