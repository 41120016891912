import React from 'react';
import { Link } from 'react-router-dom';
import fullLogo from '../../images/techtop-full-logo.svg';


class Footer extends React.Component {
  render() {
    return (
      <footer className="techtop-footer-div py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
				<img src={fullLogo} className="img-fluid d-block" alt="Techtop"/>
				<p className="m-0"><strong>Motors & Relationships Built to Last</strong></p>
				<p className="m-0">Quality is the main ingredient</p>
            </div>
			<div className="col-md-4">
				<div>
					<h3 class="color1 fw-bold w-fit-content">Quick Links</h3>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2" href="/"><i class="fas fa-home"></i> Home</a>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2" href="/products/empty"><i class="fas fa-search"></i> Search Products</a>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2" href="/contact"><i class="fas fa-address-book"></i> Contact Us</a>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2" href="/track-shipment"><i class="fas fa-truck"></i> Track your Shipment</a>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2" href="/downloads"><i class="fas fa-download"></i> Downloads</a>
				</div>
            </div>
			<div className="col-md-4">
				<div>
					<h3 class="color1 fw-bold w-fit-content">Contact Us</h3>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-1" href="/support"><i class="fas fa-at"></i> Contact Support</a>
					<a href="tel:6784365540" class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"><i class="fas fa-phone"></i> Tel. 678-436-5540</a>
					<a href="mailto:orders@techtopind.com" class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"><i class="fas fa-envelope"></i> orders@techtopind.com</a>
					<a class="link-with-icon text-dark fw-normal d-flex align-items-center gap-2"><i class="fas fa-building"></i> 2815 Colonnades Ct Peachtree Corners, GA 30071</a>
				</div>
			</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
