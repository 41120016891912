/*
* @Author: babysoft08
* @Date:   2025-01-30 19:29:09
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-01-30 19:29:34
*/
import { Link } from 'react-router-dom';
import ProductService from './../../services/ProductService/ProductService';
import SideBar from '../../components/SideBar/SideBar';
import ProductTable from '../../components/ProductTable/ProductTable';
import SearchByPartNumber from '../../components/SearchByPartNumber/SearchByPartNumber';
import React from 'react';
import './Product.css';
import GearSpinner from '../../components/GearSpinner/GearSpinner';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_detail: [], // title and description
      user_id: '',
      is_login: 0,
      filtered_products: [],
      loading: false,
    };
    this.productService = new ProductService();
    this.renderProductDetail = this.renderProductDetail.bind(this);
    // this.renderReturnToTopButton = this.renderReturnToTopButton.bind(this);
  }

  async componentDidMount() {
    this.setLoginStatus();
    await this.fetchProducts();
  }

  async fetchProducts() {
    const permalink = this.props.match.params.permalink;
    this.setState({ filtered_products: [], loading: true, product_detail: [] });
    if (!permalink.includes('empty')) {
      try {
        const request = await this.productService.getProductDetail(permalink);
        if (request.status === 200) {
          // Set the product detail (title and description).
          // Description is in html format (also contains images).
          this.setState({
            product_detail: request.data.result.product_details,
          });

          // Set the motors data.
          const filtered_products = request.data.result.filtered_products || [];
          if (filtered_products?.length > 0) {
            this.setState({ filtered_products: filtered_products });
          }
        } else {
          console.error('Error: ', request);
        }
      } catch (error) {
        console.error('Error: ', error);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  setLoginStatus() {
    if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
      this.setState({ is_login: 1 });
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const permalink = this.props.match.params.permalink;
    if (permalink !== prevProps.match.params.permalink) {
      await this.fetchProducts();
    }
  }
  renderProductDetail() {
    let product_detail = this.state.product_detail;
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="categoriesBox">
            <div className="col-md-12">
              <article className="product-details">
                <Link to="">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>{product_detail.name}</h3>
                    </div>
                  </div>
                </Link>
              </article>
            </div>
            <div className="col-md-12 mt-3">
              <article className="product">
                <div className="row">
                  <div className="col-md-12">
                    {renderHTML(product_detail.description)}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  setFilteredProducts = (products) => {
    this.setState({ filtered_products: products, product_detail: [] });
  };

  render() {
    let is_login = this.state.is_login;
    let product_detail = this.state.product_detail;
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={'/'}>Home</a>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={'/products/'}>Products</Link>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item active">
                      {product_detail.name}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row px-1">
                <div className="product-sidebar p-3 mt-2 mb-5 mx-3">
                  <div>
                    <SearchByPartNumber
                      className="mb-3"
                      sendProducts={this.setFilteredProducts}
                    />
                    <div className="d-flex text-center fw-bold text-secondary">
                      <div className="separator-line"></div>
                      <span className="mx-3"> OR </span>
                      <div className="separator-line"></div>
                    </div>
                    <SideBar sendProducts={this.setFilteredProducts} />
                  </div>
                </div>
                <div className="product-content">
                  <div className="products">
                    {product_detail?.name &&
                      product_detail?.description &&
                      this.renderProductDetail()}
                    {this.state.loading ? (
                      <GearSpinner />
                    ) : (
                      <ProductTable
                        products={this.state.filtered_products}
                        is_login={is_login}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default ProductDetail;