import React from 'react';
import { Link } from 'react-router-dom';
import ProductFBService from './../../services/ProductService/ProductFBService';
import PartService from './../../services/PartService/PartService';
import VoltageService from './../../services/VoltageService/VoltageService';
import SideBar from '../../components/SideBar/SideBar';
import ProductTable from '../../components/ProductTable/ProductTable.js';
import { API_URL } from '../../constants.js';
import GearSpinner from '../../components/GearSpinner/GearSpinner';
import SearchByPartNumber from '../../components/SearchByPartNumber/SearchByPartNumber';
import './Motor.css';

class Motor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      motor_detail: { custom_fields: {} },
      voltageOptions: [],
      is_login: localStorage.getItem('is_login') ? 1 : 0,
      user_email: localStorage.getItem('techtop_user_email') || 'empty',
      user_message: '',
      media_url: '',
      baseModel: '',
      selectedVoltage: '',
      isLoading: true,
      filtered_products: [],
      showErrorForm: false,
      emailError: '',
    };

    this.ProductFBService = new ProductFBService();
    this.VoltageService = new VoltageService();
    this.PartService = new PartService();
    this.loadDetails = this.loadDetails.bind(this);
    this.handleVoltageChange = this.handleVoltageChange.bind(this);
    this.toggleShowErrorForm = this.toggleShowErrorForm.bind(this);
    this.sendErrorReport = this.sendErrorReport.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const part_number = this.props.match.params.part_number;
    try {
      const response = await this.ProductFBService.getProductFBDetail(
        part_number
      );
      if (response?.status === 200 && response?.data?.product_detail) {
        const { product_detail, media_url } = response.data;
        const baseModel = product_detail.custom_fields['248']?.value;
        const voltageCode = product_detail.custom_fields['249']?.value;

        this.setState({
          motor_detail: product_detail,
          media_url: media_url,
          baseModel: baseModel,
        });
        if (voltageCode) {
          const voltageOptions = await this.VoltageService.getVoltageDetails(
            voltageCode
          );
          if (this.state.selectedVoltage === '') {
            this.setState({
              selectedVoltage:
                voltageOptions?.length > 0
                  ? voltageOptions[0]['Available Voltages']
                  : '',
            });
          }
          this.setState({ voltageOptions });
        }
      }
    } catch (error) {
      console.error('Error fetching motor details:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleVoltageChange(event) {
    const selectedVoltage = event.target.value;
    this.setState({ selectedVoltage, isLoading: true }, () => {
      this.loadDetails(selectedVoltage);
    });
  }

  setFilteredProducts = (products) => {
    this.setState({ filtered_products: products, product_detail: [] });
  };

  getCustomFieldValue = (fieldId) =>
    this.state.motor_detail.custom_fields[fieldId]?.value || 'N/A';

  toggleShowErrorForm = () => {
    this.setState({ showErrorForm: !this.state.showErrorForm });
  };

  renderMotorDetail() {
    const {
      motor_detail,
      is_login,
      voltageOptions,
      selectedVoltage,
      user_email,
    } = this.state;

    const downloadDatasheet = async (e) => {
      e.preventDefault();
      try {
        // The email value is used in BE for reporting purposes when failed to download datasheet.
        const response = await fetch(
          `${API_URL}/get-datasheet/${motor_detail.part_number}/${selectedVoltage}/${user_email}`
        );
        if (!response.ok) throw new Error('Failed to fetch datasheet');
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${motor_detail.part_number}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading datasheet:', error);
        this.toggleShowErrorForm();
      }
    };

    if (
      !motor_detail?.custom_fields ||
      Object.keys(motor_detail.custom_fields).length === 0
    ) {
      return (
        <div className="alert alert-danger text-center mt-2" role="alert">
          <h4 className="alert-heading fw-bold"> Sorry! Motor Not Found.</h4>
          <p>
            The motor you are looking for is currently unavailable.
            <p>
              Please try again later or{' '}
              <a href="/support" className="fw-bold">
                contact us
              </a>{' '}
              for further assistance.
            </p>
          </p>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="products">
          <div className="row">
            <div className="col-md-8">
              <h3>
                <strong>{motor_detail.part_number}</strong>
              </h3>
            </div>
            <div className="col-md-4 text-right">
              {is_login === 1 ? (
                <p>
                  <Link to={`/motors/${motor_detail.part_number}/inventory`}>
                    Show Inventory
                  </Link>
                </p>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-2">
              <article className="motors">
                <div className="table-responsive">
                  <table className="motor-table table">
                    <tbody>
                      <tr>
                        <td>Motor Group</td>
                        <td>{this.getCustomFieldValue('254')}</td>
                      </tr>
                      <tr>
                        <td>Frame Size</td>
                        <td>{this.getCustomFieldValue('253')}</td>
                      </tr>
                      <tr>
                        <td>HP</td>
                        <td>
                          {this.getCustomFieldValue('267').replace('MGN-', '')}
                        </td>
                      </tr>
                      <tr>
                        <td>RPM</td>
                        <td>
                          {this.getCustomFieldValue('266').replace('MGN-', '')}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          For which operating voltage would you like the datapack
                          to be produced?
                        </td>
                        <td>
                          <select
                            value={selectedVoltage}
                            onChange={this.handleVoltageChange}
                          >
                            {voltageOptions.map((option, index) => (
                              <option
                                key={index}
                                value={option['Available Voltages']}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>List Price</td>
                        <td>
                          $
                          {motor_detail.price
                            ? parseFloat(motor_detail.price).toFixed(2)
                            : '0.00'}
                        </td>
                      </tr>
                      <tr>
                        <td>Multiplier Code</td>
                        <td>{this.getCustomFieldValue('88')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul>
                  {/* TODO: fix 2D drawing */}
                  {/* {motor_detail.drawing_pdf_id !== '' &&
                  motor_detail.drawing_pdf_id !== null ? (
                    <li>
                      <a
                        href={
                          this.state.media_url +
                          'drawing_pdf/' +
                          motor_detail.drawing_pdf_id
                        }
                      >
                        2D drawing
                      </a>
                    </li>
                  ) : null} */}
                  {motor_detail.url_3d ? (
                    <li>
                      <a href={motor_detail.url_3d}>3D drawing</a>
                    </li>
                  ) : (
                    <li>3D drawing</li>
                  )}
                  <li>
                    <a href="#" onClick={downloadDatasheet}>
                      Download Datapack
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  async sendErrorReport() {
    try {
      const { motor_detail, selectedVoltage, user_email, user_message } =
        this.state;
      const part_number = motor_detail.part_number;
      const response = await fetch(
        `${API_URL}/report-error-datasheet/${part_number}/${selectedVoltage}/${user_email}/${user_message}`
      );
      console.log('f log after send error report, response: ', response);
      if (!response.ok) throw new Error('Failed to send error report');
    } catch (error) {
      console.error('Error sending error report:', error);
    } finally {
      this.toggleShowErrorForm();
    }
  }

  handleEmailChange = (e) => {
    e.preventDefault();
    const error = this.validateEmail(e.target.value)
      ? ''
      : 'Please enter a valid email address.';
    this.validateEmail(e.target.value);
    this.setState({ user_email: e.target.value, emailError: error });
  };

  handleMessageChange = (e) => {
    this.setState({ user_message: e.target.value });
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleFormSubmit = (event) => {
    event.preventDefault();

    const email = this.state.user_email;

    if (!email || !this.validateEmail(email)) {
      this.setState({ emailError: 'Please enter a valid email address.' });
      return;
    }

    this.setState({ emailError: '' });
    this.sendErrorReport();
  };
  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <GearSpinner />;
    }

    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            {this.state.showErrorForm ? (
              <div className="motor-modal-overlay">
                <div className="motor-modal-content">
                  <div
                    className="alert alert-danger text-center mt-2"
                    role="alert"
                  >
                    <h4 className="alert-heading fw-bold">
                      We're sorry, but it seems there is an issue downloading this datapack.
                    </h4>
                  </div>
                  <div
                    className="alert alert-info text-center mt-2"
                    role="alert"
                  >
                    <h5>
                      Please share your email address below, and we’ll send it to you directly. We apologize for the inconvenience and appreciate your understanding.
                    </h5>
                  </div>
                  <form
                    className="motor-error-report-form d-flex flex-column gap-3"
                    onSubmit={this.handleFormSubmit}
                  >
                    <div className="form-group">
                      <label htmlFor="part_number">Part Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="part_number"
                        value={this.state.motor_detail.part_number}
                        readOnly
                      />
                      <label htmlFor="voltage">Voltage</label>
                      <input
                        type="text"
                        className="form-control"
                        id="voltage"
                        value={this.state.selectedVoltage}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email_error_report_datasheet">
                        Email address*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email_error_report_datasheet"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        defaultValue={
                          this.state.user_email === 'empty'
                            ? ''
                            : this.state.user_email
                        }
                        required
                        onChange={this.handleEmailChange}
                      />
                      {this.state.emailError && (
                        <p>
                          <small className="text-danger">
                            {this.state.emailError}
                          </small>
                        </p>
                      )}
                      <label htmlFor="message">
                        Message
                        <span className="text-muted fs-6">(optional)</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="3"
                        value={this.state.user_message}
                        onChange={this.handleMessageChange}
                      ></textarea>
                    </div>
                    <div className="d-flex w-100 justify-content-evenly gap-4">
                      <button
                        type="button"
                        className="btn btn-secondary w-50 m-0 rounded"
                        onClick={this.toggleShowErrorForm}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="submit-button w-50 m-0 rounded submit-error-report"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            <section className="product-area my-1">
              <div className="row px-1">
                <div className="product-sidebar p-3 mt-2 mb-5 mx-3">
                  <SearchByPartNumber
                    className="mb-3"
                    sendProducts={this.setFilteredProducts}
                  />
                  <SideBar sendProducts={this.setFilteredProducts} />
                </div>
                <div className="product-content">
                  {this.state.filtered_products?.length === 0 ? (
                    this.renderMotorDetail()
                  ) : (
                    <ProductTable
                      products={this.state.filtered_products}
                      is_login={this.state.is_login}
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Motor;
