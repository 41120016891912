/*
* @Author: abhishek714
* @Date:   2025-02-05 12:14:36
* @Last Modified by:   abhishek714
* @Last Modified time: 2025-02-05 12:40:39
*/
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { industryData } from '../../helper/IndustryData';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


const options = {
    autoplay: true,
	lazyLoad: true,
	loop: true,
	margin:15,
	responsiveClass: true,
	autoHeight: true,
	autoplayTimeout: 7000,
	smartSpeed: 800,
	nav: true,
	dots: false,
	responsive: {
		0: {
		  items: 1
		}, 

		600: {
		  items: 2
		},

		1024: {
		  items: 4
		},

		1366: {
		  items: 4
		}
	}
};

class HomeIndustrySlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
		
		this.renderSlides = this.renderSlides.bind(this);
	}

	componentDidMount() {
		
	}

	renderSlides() {
		return(
			<React.Fragment>
				{industryData.map((value, index) => (
					<React.Fragment key={index}>
						<div className="item">
							<div className="col">
			                  <div className="techtop-infoBox3">
			                    <img src={value.image} className="img-fluid d-block w-100" alt={value.image}/>
			                  </div>
			                </div>
						</div>
					</React.Fragment>

				))}
			</React.Fragment>
		)
	}

	render() {
		return(
			<React.Fragment>
				<div className="row">
					<OwlCarousel className="owl-carousel owl-theme" {...options}> 
						{this.renderSlides()}
					</OwlCarousel>
				</div>
			</React.Fragment>
		)
	}
}
export default HomeIndustrySlider;