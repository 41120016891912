/*
* @Author: abhishek714
* @Date:   2025-02-05 12:23:54
* @Last Modified by:   abhishek714
* @Last Modified time: 2025-02-05 12:32:21
*/
import aggregateCement from '../images/industries/aggregate-cement.png';
import agribusiness from '../images/industries/agribusiness.png';
import datacenters from '../images/industries/datacenters.png';
import foodBeverage from '../images/industries/food-beverage.png';
import hazardous from '../images/industries/hazardous.png';
import hvac from '../images/industries/hvac.png';
import medical from '../images/industries/medical.png';
import mining from '../images/industries/mining.png';
import oilGas from '../images/industries/oil-gas.png';
import pulpPaper from '../images/industries/pulp-paper.png';
import utilities from '../images/industries/utilities.png';
import wasteWastewater from '../images/industries/waste-wastewater.png';

export const industryData = [
	{
		"name":"aggregate cement",
		"image":aggregateCement,
	},
	{
		"name":"agri business",
		"image":agribusiness,
	},
	{
		"name":"data centers",
		"image":datacenters,
	},
	{
		"name":"food beverage",
		"image":foodBeverage,
	},
	{
		"name":"hazardous",
		"image":hazardous,
	},
	{
		"name":"hvac",
		"image":hvac,
	},
	{
		"name":"medical",
		"image":medical,
	},
	{
		"name":"mining",
		"image":mining,
	},
	{
		"name":"oil gas",
		"image":oilGas,
	},
	{
		"name":"pulp paper",
		"image":pulpPaper,
	},
	{
		"name":"utilities",
		"image":utilities,
	},
	{
		"name":"waste Wastewater",
		"image":wasteWastewater,
	}
];